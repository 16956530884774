import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../styles/about-section.css";
import aboutImg from "../../assets/images/a-9.webp";

const AboutSection = ({ aboutClass }) => {
  return (
    <section
      className="about__section"
      style={
        aboutClass === "aboutPage"
          ? { marginTop: "0px" }
          : { marginTop: "0px" }
      }
    >
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="about__section-content aboutus">
              <h4 className="section__subtitle">About Us</h4>
              <h2 className="section__title ">Greetings <br />From Rapid Rentals</h2>
              <p className="section__description">
                Since 2023, Rapid Rentals has been delivering top-notch services to
                our esteemed clientele. With a professional team dedicated to offering
                you the finest vehicles at the most competitive rates, our utmost
                commitment and dedication has firmly established us as the premier
                choice for car rentals in Dubai. Our core values inculcated over our
                extensive tenure have allowed us to flourish in this ever-growing
                market, setting standards for excellence in the industry.
              </p>
            </div>
          </Col>

          <Col lg="6" md="6">
            <div className="about__img">
              <img src={aboutImg} alt="" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutSection;
