import React from "react";
import ava01 from "../../assets/team/thijs.jpg";
import ava02 from "../../assets/team/haris.png";
import ava03 from "../../assets/team/idrees.png";
import "../../styles/our-member.css"; // Import the CSS file

const OUR__MEMBERS = [
  {
    name: "Thijs Schrijver",
    experience: "Founder",
    fbUrl: "#",
    instUrl: "#",
    twitUrl: "#",
    linkedinUrl: "#",
    imgUrl: ava01,
  },
  {
    name: "Muhammad Haris",
    experience: "Chief Executive Officer",
    fbUrl: "#",
    instUrl: "#",
    twitUrl: "#",
    linkedinUrl: "#",
    imgUrl: ava02,
  },
  {
    name: "Muhammad Idrees",
    experience: "Operations Manager",
    fbUrl: "#",
    instUrl: "#",
    imgUrl: ava03,
  },
];

const OurMembers = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        width: '100%',
        padding: '2rem',
      }}
    >
      {OUR__MEMBERS.map((item, index) => (
        <div
          key={index}
          className="member-card"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: '1px solid #7c8a972d',
            borderRadius: '4px',
            padding: '1rem',
            margin: '0.5rem',
            boxSizing: 'border-box',
          }}
        >
          <div style={{ position: 'relative', cursor: 'pointer', width: '100%' }}>
            <img
              src={item.imgUrl}
              alt={item.name}
              style={{
                borderRadius: '4px',
                width: '100%',
                height: '460px',
                objectFit: 'cover',
              }}
            />
          </div>
          <h6 style={{ textAlign: 'center', marginTop: '1rem' }}>{item.name}</h6>
          <p style={{ textAlign: 'center', color: '#666' }}>{item.experience}</p>
        </div>
      ))}
    </div>
  );
};

export default OurMembers;
