import React, { useEffect, useState } from "react";
import { FaFacebook, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import carData from "../assets/data/carData";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import { useParams } from "react-router-dom";
import BookingForm from "../components/UI/BookingForm";
import { FaPhone } from "react-icons/fa6";
import { MdEmail, MdGpsFixed, MdAirlineSeatReclineNormal } from "react-icons/md";
import { RiHomeOfficeFill } from "react-icons/ri";
import { IoMdSettings } from "react-icons/io";
import { FaLocationArrow, FaGasPump, FaCar } from "react-icons/fa";
import "../styles/contact.css";

const socialLinks = [
  {
    url: "https://www.facebook.com",
    icon: <FaFacebook style={{ color: "#D48383" }} />,
  },
  {
    url: "https://www.instagram.com/rapidrentalsuae/",
    icon: <FaInstagram style={{ color: "#D48383" }} />,
  },
  {
    url: "https://www.linkedin.com/company/rapid-rentals-ae",
    icon: <FaLinkedinIn style={{ color: "#D48383" }} />,
  },
];

const CarDetails = () => {
  const { slug } = useParams();
  const singleCarItem = carData.find((item) => item.carName === slug);

  const [selectedColor, setSelectedColor] = useState(singleCarItem.colors[0]);
  const [carImage, setCarImage] = useState(singleCarItem.imgUrl[selectedColor]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [singleCarItem]);

  useEffect(() => {
    setCarImage(singleCarItem.imgUrl[selectedColor]);
  }, [selectedColor, singleCarItem.imgUrl]);

  return (
    <Helmet title={singleCarItem.carName}>
      <section>
        <Container>
          <Row>
            <Col lg="6">
              <img src={carImage} alt="" className="w-100" />
            </Col>

            <Col lg="6">
              <div className="car__info">
                <h2 className="section__title">{singleCarItem.carName}</h2>

                <div className="d-flex align-items-center gap-5 mb-4 mt-3">
                  <h6 className="rent__price fw-bold fs-4" style={{ color: "#D48383" }}>
                    {singleCarItem.model}
                  </h6>
                </div>

                <p className="section__description">{singleCarItem.description}</p>

                <div className="d-flex align-items-center mt-3" style={{ columnGap: "4rem" }}>
                  <span className="d-flex align-items-center gap-1 section__description">
                    <FaCar style={{ color: "#D48383" }} />
                    {singleCarItem.model}
                  </span>

                  <span className="d-flex align-items-center gap-1 section__description">
                    <IoMdSettings style={{ color: "#D48383" }} />
                    {singleCarItem.automatic}
                  </span>

                  <span className="d-flex align-items-center gap-1 section__description">
                    <FaGasPump style={{ color: "#D48383" }} />
                    {singleCarItem.speed}
                  </span>
                </div>

                <div className="d-flex align-items-center mt-3" style={{ columnGap: "2.8rem" }}>
                  <span className="d-flex align-items-center gap-1 section__description">
                    <MdGpsFixed style={{ color: "#D48383" }} />
                    {singleCarItem.gps}
                  </span>

                  <span className="d-flex align-items-center gap-1 section__description">
                    <MdAirlineSeatReclineNormal style={{ color: "#D48383" }} />
                    {singleCarItem.seatType}
                  </span>

                  <span className="d-flex align-items-center gap-1 section__description">
                    <RiHomeOfficeFill style={{ color: "#D48383" }} />
                    {singleCarItem.brand}
                  </span>
                </div>

                {/* Available Colors Section */}
                <div className="color-options mt-4">
                  <h4>Available Colors:</h4>
                  <div className="color-list d-flex gap-2">
                    {singleCarItem.colors.map((color, index) => (
                      <span
                        key={index}
                        className="color-dot"
                        style={{
                          backgroundColor: color,
                          width: "20px",
                          height: "20px",
                          borderRadius: "50%",
                          display: "inline-block",
                          border: "1px solid #ccc",
                          cursor: "pointer",
                        }}
                        onClick={() => setSelectedColor(color)}
                      ></span>
                    ))}
                  </div>
                </div>
              </div>
            </Col>

            <Col lg="7" className="mt-5">
              <div className="booking-info mt-5">
                <h5 className="mb-4 fw-bold">Booking Information</h5>
                <BookingForm />
              </div>
            </Col>

            <Col lg="5" md="5">
              <div className="contact__info equal-height contact">
                <h5 className="fw-bold">Contact Information</h5>
                <div className="d-flex align-items-center gap-2">
                  <h5 className="fs-6 mb-0">
                    <FaLocationArrow style={{ color: "#D48383" }} />
                  </h5>
                  <p className="section__description mb-0">
                    IFZA Building - Block A, Dubai Silicon Oasis, Dubai
                  </p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <h5 className="fs-6 mb-0">
                    <FaPhone style={{ color: "#D48383" }} />
                  </h5>
                  <p className="section__description mb-0">
                    <a href="tel:+971545082661" style={{ color: "black", textDecoration: "none" }}>
                      +971-54-5082661
                    </a>,{" "}
                    <a href="tel:+971558654999" style={{ color: "black", textDecoration: "none" }}>
                      +971 55 865 4999
                    </a>
                  </p>
                </div>

                <div className="d-flex align-items-center gap-2">
                  <h5 className="mb-0 fs-6">
                    <MdEmail style={{ color: "#D48383" }} />
                  </h5>
                  <p className="section__description mb-0">
                    <a href="mailto:info@rapidenterprises.ae" style={{ color: "black", textDecoration: "none" }}>
                      info@rapidenterprises.ae
                    </a>
                  </p>
                </div>

                <h5 className="fw-bold mt-4">Follow Us</h5>

                <div className="d-flex align-items-center gap-4 mt-3">
                  {socialLinks.map((item, index) => (
                    <a
                      href={item.url}
                      key={index}
                      className="social__link-icon"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.icon}
                    </a>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default CarDetails;
