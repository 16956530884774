import icon1 from "../icons/icon2.png"
import icon2 from "../icons/icon5.png"
import icon3 from "../icons/icon6.png"
import icon4 from "../icons/icon4.png"
import icon5 from "../icons/icon7.png"
import icon6 from "../icons/icon9.png"
const serviceData = [
  {
    id: 1,
    title: "Diverse Fleet",
    img: icon1,
    desc: " We have categorized our fleet for rental into economy cars, mid range cars and SUVs so you can select the appropriate car as per the requirements for your trip.",
  },

  {
    id: 2,
    title: "Fair Pricing",
    img: icon2,
    desc: " At Rapid Rentals, we make sure to clarify all costs and fees for you beforehand to eliminate any complications. We guarantee the most affordable and competitive prices for rental.",
  },

  {
    id: 3,
    title:  "Flexible Rental Time",
    img: icon3,
    desc: " We understand the need for flexibility, we aim to provide you with freedom to rent for however long you may want. We have you covered if you need to rent for just a day or even a few hours.",
  },

  {
    id: 4,
    title: "Key Locations",
    img: icon4,
    desc: " We have ensured that our service covers all strategic locales of the city including airports, commercial spaces, and urban hubs to facilitate greater ease of access for you.",
  },

  {
    id: 5,
    title: "User-Friendly Registration",
    img: icon5,
    desc: "Our registration process was designed with major considerations regarding user ease in mind to provide you with a straightforward interface for creating your bookings 24/7.",
  },

  {
    id: 6,
    title: "Vehicle Efficiency",
    img: icon6,
    desc: "To ensure our service fulfills your expectations and is up to the standard, we relentlessly invest in car maintenance and have each vehicle sanitized and cleaned after every use.",
  },
];

export default serviceData;
