import React from "react";
import { Container } from "reactstrap";
import "../../styles/common-section.css";

const CommonSection = ({ title, imageUrl }) => {
  const sectionStyle = {
    background: `linear-gradient(rgba(107, 18, 0, 0.4), rgba(164, 21, 11, 0.4)), url(${imageUrl})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <section className="common__section mb-5" style={sectionStyle}>
      <Container className="text-center">
        <h1 className="text-light">{title}</h1>
        {/* Add any additional content here */}
      </Container>
    </section>
  );
};

export default CommonSection;
