import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../styles/about-section.css";
import aboutImg from "../../assets/images/a-9.webp";

const AboutSectionLanding = ({ aboutClass }) => {
  return (
    <section
      className="about__section"
      style={
        aboutClass === "aboutPage"
          ? { marginTop: "0px" }
          : { marginTop: "0px" }
      }
    >
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="about__section-content aboutus">
              <h4 className="section__subtitle">About Us</h4>
              <h2 className="section__title ">Greetings <br />From Rapid Rentals</h2>
              <p className="section__description">
              Ever since our inception, Rapid Rentals has been delivering top-notch services to our esteemed clientele, with a professional team dedicated to offering you the finest vehicles at the most competitive rates. In choosing our service, you are entitled to complete transparency and expert guidance in determining the best vehicle for your travels. Our utmost commitment and dedication has firmly established us as the premier choice for car rentals in Dubai. 

              </p>
            </div>
          </Col>

          <Col lg="6" md="6">
            <div className="about__img">
              <img src={aboutImg} alt="" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutSectionLanding;
