import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import "../../styles/header.css";
import logo from "../../assets/icons/logo.png";
import { FaPhoneAlt } from "react-icons/fa";

const navLinks = [
  { path: "/home", display: "Home" },
  { path: "/about-us", display: "About Us" },
  { path: "/our-fleet", display: "Our Fleet" },
  { path: "/our-blogs", display: "Our Blogs" },
  { path: "/contact-us", display: "Book A Car" },
];

const Header = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [closing, setClosing] = useState(false);
  const location = useLocation();

  const handleCloseMenu = () => {
    setClosing(true);
    setTimeout(() => {
      setToggleMenu(false);
      setClosing(false);
    }, 1000); // Match the duration of the closing animation
  };

  return (
    <header className="header">
      <div className="header__top">
        <Container className="pm">
          <Row className="pt-2">
            <Col lg="6" md="6" sm="6">
              <div className="header__top__left">
                <span>info@rapidenterprises.ae</span>
                <span className="header__top__help">
                  <FaPhoneAlt /> +971 55 865 4999
                </span>
              </div>
            </Col>
            <Col lg="6" md="6" sm="6">
              <div className="header__top__right d-flex align-items-center justify-content-end gap-3">
                <div className="header__button">
                  <Link to="/contact-us">
                    <FaPhoneAlt /> Call Us Now
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <nav className="a_navbar">
        <div className="logo-container logo-h">
          <Link to="/">
            {/* Wrap logo with Link */}
            <img src={logo} alt="logo" className="logo-header " />
          </Link>
        </div>
        <ul className="a_navbar-links">
          {navLinks.map((item, index) => (
            <li
              className={`p__opensans ${location.pathname === item.path ? "active" : ""}`}
              key={index}
            >
              <Link to={item.path} className="links">
                {item.display}
              </Link>
            </li>
          ))}
        </ul>
        <div className="a_navbar-smallscreen">
          <GiHamburgerMenu
            fontSize={28}
            className="menu-icon"
            onClick={() => setToggleMenu(true)}
          />
          {toggleMenu && (
            <div className={`a_navbar-smallscreen_overlay ${closing ? "close" : "open"}`}>
              <div className="">
                <img src={logo} alt="logo" className="logo-header" />
              </div>
              <MdClose
                fontSize={28}
                className="overlay__close cross"
                onClick={handleCloseMenu}
              />
              <ul className="a_navbar-smallscreen-links">
                {navLinks.map((item, index) => (
                  <li
                    className={`p__opensans ${location.pathname === item.path ? "active" : ""}`}
                    key={index}
                    onClick={handleCloseMenu}
                  >
                    <Link to={item.path} style={{ color: "black", textDecoration: "none" }}>
                      {item.display}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Header;
