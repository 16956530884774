import React from "react";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import { FaFacebook, FaInstagram, FaLinkedinIn, FaPhone, FaLocationArrow } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import BookingForm from "../components/UI/BookingForm";
import "../styles/contact.css";
import backgroundImg from "../assets/images/a-6.webp";

const socialLinks = [
  {
    url: "https://www.facebook.com/rapidrentalsae",
    icon: <FaFacebook />,
  },
  {
    url: "https://www.instagram.com/rapidrentalsuae/",
    icon: <FaInstagram />,
  },
  {
    url: "https://www.linkedin.com/company/rapid-rentals-ae",
    icon: <FaLinkedinIn />,
  },
];

const Contact = () => {
  return (
    <Helmet title="Contact">
      <CommonSection title="Connect With Us" imageUrl={backgroundImg} />
      <section>
        <Container>
          <Row>
            <Col lg="7" className="mt-5">
              <div className="booking-info mt-5">
                <h5 className="mb-4 fw-bold">Booking Information</h5>
                <BookingForm />
              </div>
            </Col>

            <Col lg="5" md="5">
              <div className="contact__info equal-height contact">
                <h5 className="fw-bold">Contact Information</h5>
                <div className="d-flex align-items-center gap-2">
                  <h5 className="fs-6 mb-0">
                    <FaLocationArrow />
                  </h5>
                  <p className="section__description mb-0">
                    A3, L3, 305-C, IFZA Business Park, DSO, Dubai, UAE.
                  </p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <h5 className="fs-6 mb-0">
                    <FaPhone />
                  </h5>
                  {/* <p className="section__description mb-0">
                    <a href="tel:+971503661754" style={{ color: "black", textDecoration: "none" }}>
                      +971 50 366 1754
                    </a>
                  </p> */}
                  <p className="section__description mb-0">
                    <a href="tel:+971558654999" style={{ color: "black", textDecoration: "none" }}>
                      +971 55 865 4999
                    </a>
                  </p>
                </div>

                <div className="d-flex align-items-center gap-2">
                  <h5 className="mb-0 fs-6">
                    <MdEmail />
                  </h5>
                  <p className="section__description mb-0">
                    <a href="mailto:info@rapidenterprises.ae" style={{ color: "black", textDecoration: "none" }}>
                      info@rapidenterprises.ae
                    </a>
                  </p>
                </div>

                <h5 className="fw-bold mt-4">Follow Us</h5>

                <div className="d-flex align-items-center gap-4 mt-3">
                  {socialLinks.map((item, index) => (
                    <a
                      href={item.url}
                      key={index}
                      className="social__link-icon"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.icon}
                    </a>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Contact;
