import React from "react";

import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import AboutSection from "../components/UI/AboutSection";
import { Container, Row, Col } from "reactstrap";
import BecomeDriverSection from "../components/UI/BecomeDriverSection";

import driveImg from "../assets/images/a-13.webp";
import OurMembers from "../components/UI/OurMembers";
import "../styles/about.css";
import backgroundImg from "../assets/images/a-1.webp"
const About = () => {
  return (
    <Helmet title="About">
     <CommonSection title="Learn More About Us" imageUrl={backgroundImg} />

      <AboutSection aboutClass="aboutPage" />

      <section className="about__page-section">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="about__page-img">
                <img src={driveImg} alt="" className="w-100 rounded-3" />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="about__page-content">
              <h2 className="section__title ">Our Values</h2>

                <p className="section__description">
                We have determined a framework constituting some principles we
make sure to never compromise on. In choosing our service, you are
entitled to complete transparency and expert guidance in determining
the best vehicle for your travels. Alongside this, we offer you versatile,
meticulously well kept and hygienic vehicles for hire because we
recognize the importance of adding value to your experiences. Safety
and comfort are crucial for you to enjoy your commute around the city.
                </p>

                

                {/* <div className=" d-flex align-items-center gap-3 mt-4">
                  <span className="fs-4">
                    <i class="ri-phone-line"></i>
                  </span>

                  <div>
                    <h6 className="section__subtitle">Need Any Help?</h6>
                    <h4>+00123456789</h4>
                  </div>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <BecomeDriverSection />

      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h6 className="section__subtitle">Meet</h6>
              <h2 className="section__title">Our Team</h2>
            </Col>
            <OurMembers />
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default About;
