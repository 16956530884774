import React from "react";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/car-item.css";
import { FaCar } from "react-icons/fa";
import { RiHomeOfficeFill } from "react-icons/ri";
import { MdGpsFixed } from "react-icons/md";
import { FaGasPump } from "react-icons/fa";
const CarItem = (props) => {
  const { imgUrl1, model, carName, automatic, speed, price } = props.item;

  return (
    <Col lg="4" md="4" sm="6" className="mb-5">
      <div className="car__item">
        <div className="car__img padding-car">
          <img src={imgUrl1} alt="" className="w-100" />
        </div>

        <div className="car__item-content mt-4">
          <h4 className="section__title text-center">{carName}</h4>
          {/* <h6 className="rent__price text-end padding-car ">
            {model}
          </h6> */}

          <div className=" padding-car flex flex-row car__item-info d-flex align-items-center justify-content-between mt-3 mb-4">
            <span className=" d-flex align-items-center gap-1">
            <FaCar style={{ color: "#D48383" }}/> {model}
            </span>
            <span className=" d-flex align-items-center gap-1">
            <RiHomeOfficeFill style={{ color: "#D48383" }}/>
                 {automatic}
            </span>
            <span className=" d-flex align-items-center gap-1">
            <FaGasPump style={{ color: "#D48383" }}/> {speed}
            </span>
          </div>

          <button className=" w-50 car__item-btn car__btn-rent">
            <Link to={`/our-fleet/${carName}`}>Rent</Link>
          </button>

          <button className=" w-50 car__item-btn car__btn-details">
            <Link to={`/our-fleet/${carName}`}>Details</Link>
          </button>
        </div>
      </div>
    </Col>
  );
};

export default CarItem;
