import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
import "../../styles/booking-form.css";
import { Form, FormGroup } from "reactstrap";
import emailjs from '@emailjs/browser';

const BookingForm = () => {
  const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    rentalmethod: "",
    destination: "",
    payment: "",
    rentalType: "",
    car: "",
    journeyDate: "",
    carType: "",
    specialInstructions: "",
    fromDate: today,
    toDate: today
  });

  const navigate = useNavigate(); // Initialize useNavigate

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const submitHandler = (event) => {
    event.preventDefault();

    // Ensure fromDate and toDate are set to today's date if empty
    const updatedFormData = {
      ...formData,
      fromDate: formData.fromDate || today,
      toDate: formData.toDate || today
    };

    // Send email using emailjs
    emailjs.sendForm(
      "service_360cr8p",
      "template_lu2u4ng",
      event.target,
      "6vPtQ_X5uoFEoGdEM"
    ).then((result) => {
      console.log('Email successfully sent!', result.text);
      // Redirect to the homepage after successful form submission
      navigate("/"); // Redirect to homepage
    }, (error) => {
      console.error('Error sending email:', error.text);
    });

    // Clear the form data
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      rentalmethod: "",
      destination: "",
      payment: "",
      rentalType: "",
      car: "",
      journeyDate: "",
      carType: "",
      specialInstructions: "",
      fromDate: today,
      toDate: today
    });
  };

  return (
    <Form onSubmit={submitHandler}>
      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        <input
          type="text"
          placeholder="First Name"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          required
        />
      </FormGroup>
      <FormGroup className="booking__form d-inline-block me-1 mb-4">
        <input
          type="text"
          placeholder="Last Name"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          required
        />
      </FormGroup>

      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        <input
          type="email"
          placeholder="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </FormGroup>
      <FormGroup className="booking__form d-inline-block me-1 mb-4">
        <input
          type="number"
          placeholder="Phone Number"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          required
        />
      </FormGroup>

      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        <select
          name="rentalmethod"
          value={formData.rentalmethod}
          onChange={handleChange}
          required
        >
          <option value="">Select Your Rental Method</option>
          <option value="Self Pickup">Self Pickup</option>
          <option value="Delivery">Delivery</option>
        </select>
      </FormGroup>

      <FormGroup className="booking__form d-inline-block me-1 mb-4">
        <input
          type="text"
          placeholder="Destination"
          name="destination"
          value={formData.destination}
          onChange={handleChange}
          required
        />
      </FormGroup>

      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        <select
          name="payment"
          value={formData.payment}
          onChange={handleChange}
          required
        >
          <option value="">Select Payment Method</option>
          <option value="Stripe">Stripe</option>
          <option value="Debit/Credit Cards">Debit/Credit Cards</option>
          <option value="Bank Transfer">Bank Transfer</option>
        </select>
      </FormGroup>
      <FormGroup className="booking__form d-inline-block me-1 mb-4">
        <select
          name="rentalType"
          value={formData.rentalType}
          onChange={handleChange}
          required
        >
          <option value="">Select Rental Type</option>
          <option value="Hourly Car Rental">Hourly Car Rental</option>
          <option value="Daily Car Rental">Daily Car Rental</option>
          <option value="Weekly Car Rental">Weekly Car Rental</option>
          <option value="Monthly Car Rental">Monthly Car Rental</option>
        </select>
      </FormGroup>

      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        <select
          name="carType"
          value={formData.carType}
          onChange={handleChange}
          required
        >
          <option value="">Select Car Type</option>
          <option value="economy">Economy Car</option>
          <option value="midrange">Mid-range SUV</option>
          <option value="SUV">SUV</option>
        </select>
      </FormGroup>

      <FormGroup className="booking__form d-inline-block me-1 mb-4">
        <select
          name="car"
          value={formData.car}
          onChange={handleChange}
          required
        >
          <option value="">Select Car</option>
          <option value="Nissan Sunny">Nissan Sunny</option>
          <option value="Geely Emgrand">Geely Emgrand</option>
          <option value="Mitsubishi Attrage">Mitsubishi Attrage</option>
          <option value="Hyundai Grand i10">Hyundai Grand i10</option>
          <option value="JAC S3">JAC S3</option>
          <option value="Maruti Suzuki">Maruti Suzuki</option>
          <option value="JAC J7">JAC J7</option>
          <option value="Jetoure X70">Jetoure X70</option>
          <option value="MG ZS">MG ZS</option>
        </select>
      </FormGroup>

      <h6>From:</h6>
      <FormGroup className="booking__form d-inline-block me-4 mb-4 date">
        <input
          type="date"
          value={formData.fromDate}
          name="fromDate"
          onChange={handleChange}
          required
          className="date"
          placeholder="Select From Date"
        />
      </FormGroup>

      <h6>To:</h6>
      <FormGroup className="booking__form d-inline-block me-4 mb-4 date">
        <input
          type="date"
          value={formData.toDate}
          name="toDate"
          onChange={handleChange}
          required
          className="date"
          placeholder="Select To Date"
        />
      </FormGroup>

      <FormGroup>
        <textarea
          rows={5}
          className="textarea booking__form"
          placeholder="Special Instructions"
          name="specialInstructions"
          value={formData.specialInstructions}
          onChange={handleChange}
        ></textarea>
      </FormGroup>

      <div className="payment text-start mt-5">
        <button className="contact__btn" type="submit">Book Now</button>
      </div>
    </Form>
  );
};

export default BookingForm;
