import React from "react";
import { Container, Row } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import BlogList from "../components/UI/BlogList";
import backgroundImg from "../assets/images/a-31.webp"
const Blog = () => {
  return (
    <Helmet title="Blogs">
      <CommonSection title="Explore Our Blogs" imageUrl={backgroundImg} />
      <section>
        <Container>
          <Row>
            <BlogList />
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Blog;
