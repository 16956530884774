import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
import "../../styles/booking-formLanding.css";
import { Form, FormGroup } from "reactstrap";
import emailjs from '@emailjs/browser';

const BookingFormLanding = () => {
  const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    rentalmethod: "",
    destination: "",
    payment: "",
    rentalType: "",
    car: "",
    journeyDate: "",
    carType: "",
    specialInstructions: "",
    fromDate: today,
    toDate: today
  });

  const navigate = useNavigate(); // Initialize useNavigate

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const submitHandler = (event) => {
    event.preventDefault();

    // Ensure fromDate and toDate are set to today's date if empty
    const updatedFormData = {
      ...formData,
      fromDate: formData.fromDate || today,
      toDate: formData.toDate || today
    };

    // Send email using emailjs
    emailjs.sendForm(
      "service_360cr8p",
      "template_lu2u4ng",
      event.target,
      "6vPtQ_X5uoFEoGdEM"
    ).then((result) => {
      console.log('Email successfully sent!', result.text);
      // Redirect to the homepage after successful form submission
      navigate("/"); // Redirect to homepage
    }, (error) => {
      console.error('Error sending email:', error.text);
    });

    // Clear the form data
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      rentalType: "",
      specialInstructions: "",
     
    });
  };

  return (
    <Form onSubmit={submitHandler}>
      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        <input
          type="text"
          placeholder="Name"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          required
        />
      </FormGroup>
      {/* <FormGroup className="booking__form d-inline-block me-1 mb-4">
        <input
          type="text"
          placeholder="Last Name"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          required
        />
      </FormGroup> */}

      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        <input
          type="email"
          placeholder="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </FormGroup>
      <FormGroup className="booking__form d-inline-block me-1 mb-4">
        <input
          type="number"
          placeholder="Phone Number"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          required
        />
      </FormGroup>

      


    
      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        <select 
          name="rentalType"
          value={formData.rentalType}
          onChange={handleChange}
          required
        >
          <option value="">Rental Type</option>
          <option value="Short Term Rental">Short Term </option>
          <option value="Long Term Rental">Long Term </option>
        
        </select>
      </FormGroup>


      

      

     

      <FormGroup>
        <textarea
          rows={5}
          className="textarea booking__form"
          placeholder="Special Instructions"
          name="specialInstructions"
          value={formData.specialInstructions}
          onChange={handleChange}
        ></textarea>
      </FormGroup>

      <div className="payment text-center mt-2">
        <button className="contact__btn" type="submit">Submit</button>
      </div>
    </Form>
  );
};

export default BookingFormLanding;
