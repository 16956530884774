import React from "react";
import "../../styles/become-driver.css";
import { Container, Row, Col } from "reactstrap";

import driverImg from "../../assets/images/a-11.webp";

const BecomeDriverSection = () => {
  return (
    <section className="become__driver">
      <h2 className="section__title become__driver-title text-center">
            Let the Ride of Your <br />Life Begin With Us Now
            </h2>
    </section>
  );
};

export default BecomeDriverSection;
