import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import BookingFormLanding from "../components/UI/BookingFormLanding";
import AboutSectionLanding from "../components/UI/AboutSectionLanding";
import ServicesList from "../components/UI/ServicesList";
import carData from "../assets/data/carData";
import CarItem from "../components/UI/CarItem";
import { FaFacebook, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import logo from "../assets/icons/logo.png";
import nissan from "../assets/cars/car2.png"; // Assuming this is your car image
import "./../styles/LandingPage.css";

const socialLinks = [
  {
    url: "https://www.instagram.com/rapidrentalsuae/",
    icon: <FaFacebook />,
  },
  {
    url: "https://www.instagram.com/rapidrentalsuae/",
    icon: <FaInstagram />,
  },
  {
    url: "https://www.linkedin.com/company/rapid-rentals-ae",
    icon: <FaLinkedinIn />,
  },
];

const LandingPage = () => {
  const [showPopup, setShowPopup] = useState(true);

  // Close popup function
  const closePopup = () => {
    setShowPopup(false);
  };

  // Close popup after 20 seconds (adjust timing as needed)
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(false);
    }, 20000); // 20000 milliseconds = 20 seconds
    return () => clearTimeout(timer);
  }, []);

  return (
    <Helmet title="landing-page">
      <section className="landing-page-bg">
        <Container>
          <Row className="align-items-center">
            {/* Left Section with Red Background */}
            <Col md="6" lg="6">
              <div className="section-left">
                <h5 className="text-light mb-3">Your Reliable Rental Partner</h5>
                <h1 className="text-light mb-2">Choose the best for you</h1>
                <div className="red-bg">
                  <div className="red-div">Economy Car</div>
                  <div className="red-div">Mid Range SUV</div>
                  <div className="red-div">SUV</div>
                </div>
              </div>
            </Col>

            {/* Right Section with Booking Form */}
            <Col md="6" lg="5">
              <div className="section-right">
                <div className="booking-info border p-4 text-center">
                  <h5 className="mb-4 fw-bold">Get In Touch</h5>
                  <BookingFormLanding />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* About Section */}
      <AboutSectionLanding />

      {/* Services Section */}
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h6 className="section__subtitle">Explore</h6>
              <h2 className="section__title">What we offer you</h2>
            </Col>
            <ServicesList />
          </Row>
        </Container>
      </section>

      {/* Popular Picks Section */}
      <section>
        <Container>
          <Row>
            <Col lg="12" className="text-center mb-5">
              <h6 className="section__subtitle">Most Rented</h6>
              <h2 className="section__title">Popular Picks</h2>
            </Col>
            {carData.slice(0, 3).map((item) => (
              <CarItem item={item} key={item.id} />
            ))}
          </Row>
        </Container>
      </section>

      {/* Popup */}
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup">
            {/* Logo */}
            
              <img src={logo} alt="Logo" style={{ width: "200px", height: "auto" }} />
            

            {/* Offer Line */}
            <div className="popup-offer">
              <p className="offer-line">Limited Time Offer!</p>
            </div>

            {/* Content */}
            <div className="popup-content">
              <Row>
                <Col xs="12" lg="6">
                  <div className="popup-text">
                    <div>
                      <span className="headingb">NISSAN SUNNY</span>
                      <p className="mt-2">2024</p>
                      <p className="redb">AS LOW AS</p>
                      <p className="red-text">1500 AED / MONTH</p>
                    </div>
                  </div>
                </Col>
                <Col xs="12" lg="6">
                  <div className="popup-image">
                    <img src={nissan} alt="Car Image" style={{ transform: "scaleX(-1)" }} />
                  </div>
                </Col>
              </Row>
              <div className="popup-actions">
                <button className="contact__btn red" onClick={closePopup}>Book Now</button>
              </div>
            </div>

            {/* Close Button */}
            <button className="popup-close" onClick={closePopup}>
              &times; {/* Close symbol */}
            </button>
          </div>
        </div>
      )}

      {/* Footer */}
      {/* <Footer /> */}
    </Helmet>
  );
};

export default LandingPage;
