import mgsilver from "../cars/mg-5-silver.png";
import mgwhite from "../cars/mg-5-white.png";
import sunnywhite from "../cars/car2.png";
import gb from "../cars/geely-blue.png";
import gw from "../cars/geely-white.png";
import gg from "../cars/gelly-grey.png";
import Mitsubishiw from "../cars/car4.png";
import hw from "../cars/car5.png";
import jackgrey from "../cars/jac-grey.png";
// import jackblack from "../cars/jac-grey.png";
import img07 from "../cars/car7.png";
import redjacj7 from "../cars/car8.png";
import jetourwhite from "../cars/car9.png";
import img10 from "../cars/car10.png";
import img11 from "../cars/car-11.png";
import aw from "../cars/car-12.png";
import ab from "../cars/alsvin-black.png";

import img13 from "../cars/mg-gt.png";
import sunnysilver from "../cars/sunny-silver.png"

const carData = [
  {
    carName: "Nissan Sunny",
    imgUrl1:sunnywhite,
    imgUrl: {
      white: sunnywhite,
      silver: sunnysilver,
    },
    model: "2024",
    description: "The 2024 Nissan Sunny is a compact car that offers excellent value for money. It comes with many driver assisting features such as blind-spot warning as standard. Good seating and a big boot are other plus points.",
    automatic: "Automatic",
    speed: "Petrol",
    gps: "GPS Navigation",
    seatType: "5 seats",
    brand: "Nissan",
    colors: ["white", "silver"],
  },
  {
    carName: "JAC S3",
    imgUrl1:jackgrey,
    imgUrl: {
      black: jackgrey,
      silver: jackgrey,
      grey: jackgrey,
    },
    model: "2024",
    description: "The new JAC S3 model is a new urban crossover designed for the young generation. It features a fashionable appearance and intelligent specifications.",
    automatic: "Automatic",
    speed: "Petrol",
    gps: "GPS Navigation",
    seatType: "5 seats",
    brand: "JAC",
    colors: ["black", "silver", "grey"],
  },
  {
    carName: "Jetour X70",
    imgUrl1:jetourwhite,
    imgUrl: {
      white: jetourwhite,
    },
    model: "2023",
    description: "The X70 Plus, the largest and most costly car in the Jetour family, is both sporty and classy. It features a large front grille and a high-class cabin.",
    automatic: "Automatic",
    speed: "Petrol",
    gps: "GPS Navigation",
    seatType: "5 seats",
    brand: "Chery",
    colors: ["white"],
  },
  {
    carName: "JAC J7",
    imgUrl1:redjacj7,
    imgUrl: {
      red: redjacj7,
    },
    model: "2023",
    description: "The JAC J7 is a mid-size sport sedan with a 1.5L turbocharged engine, offering excellent technology and infotainment.",
    automatic: "Automatic",
    speed: "Petrol",
    gps: "GPS Navigation",
    seatType: "5 seats",
    brand: "Jianghuai Automobile",
    colors: ["red"],
  },
  {
    carName: "MG 5",
    imgUrl1:mgsilver,

    imgUrl: {
      silver: mgsilver,
      white: mgwhite,
    },
    model: "2024",
    description: "The MG 5 is an exciting sedan, suitable for both work and leisure. It offers three trim levels and enables you to take on life with elegance and sophistication.",
    automatic: "Automatic",
    speed: "Petrol",
    gps: "GPS Navigation",
    seatType: "5 seats",
    brand: "SAIC Motor",
    colors: ["white","silver"],
  },
  {
    carName: "Geely Emgrand",
    imgUrl1:gw,

    imgUrl: {
      blue: gb,
      white: gw,
      grey: gg,
    },
    model: "2024",
    description: "The all-new Emgrand comes with a well-refined design and a high-quality interior. It offers a five-seater vehicle with the best suede seats.",
    automatic: "Automatic",
    speed: "Petrol",
    gps: "GPS Navigation",
    seatType: "5 seats",
    brand: "Geely",
    colors: ["blue", "white", "grey"],
  },
  {
    carName: " Attrage",
    imgUrl1:Mitsubishiw,

    imgUrl: {
      white: Mitsubishiw,
    },
    model: "2024",
    description: "The Mitsubishi Attrage features excellent mileage, sleek styling, and utility provisions. It has a spacious and well-designed cabin with easy-to-read instruments.",
    automatic: "Automatic",
    speed: "Petrol",
    gps: "GPS Navigation",
    seatType: "5 seats",
    brand: "Mitsubishi Motors",
    colors: ["white"],
  },
  {
    carName: "Hyundai Grand i10",
    imgUrl1:hw,

    imgUrl: {
      white: hw,
    },
    model: "2023",
    description: "The Hyundai Grand i10 is a reliable, fuel-efficient city hatchback. It features a spacious and welcoming interior with attractive black and silver colors.",
    automatic: "Automatic",
    speed: "Petrol",
    gps: "GPS Navigation",
    seatType: "5 seats",
    brand: "Hyundai",
    colors: ["white"],
  },
  {
    carName: "Suzuki Dzire",
    imgUrl1:img07,
    imgUrl: {
      silver: img07,
    },
    model: "2023",
    description: "The Suzuki Dzire is a genuine sedan with superbly designed curves and chrome finishing. It offers a spacious cabin and features that ensure comfort and convenience.",
    automatic: "Automatic",
    speed: "Petrol",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    brand: "Maruti Suzuki",
    colors: ["silver"],
  },
  {
    carName: "MG ZS",
    imgUrl1:img10,

    imgUrl: {
      white: img10,
    },
    model: "2023",
    description: "The MG ZS is a cost-effective SUV with ample space and better equipment than its alternatives. It offers a comfortable interior and standard fabric seats.",
    automatic: "Automatic",
    speed: "Petrol",
    gps: "GPS Navigation",
    seatType: "5 seats",
    brand: "MG ZS",
    colors: ["white"],
  },
  {
    carName: "Suzuki Ertiga",
    imgUrl1:img11,

    imgUrl: {
      white: img11,
    },
    model: "2024",
    description: "The Ertiga features a sleek and aerodynamically designed body. It has a smart dashboard and luxurious two-tone interior, complemented by a dynamic performance.",
    automatic: "Automatic",
    speed: "Petrol",
    gps: "GPS Navigation",
    seatType: "7 seats",
    brand: "Maruti Suzuki",
    colors: ["white"],
  },
  {
    carName: "Changan Alsvin",
    imgUrl1:ab,

    imgUrl: {
      black: ab,
      white: aw,
    },
    model: "2024",
    description: "A stylish and budget-friendly compact sedan with a sleek design and practical features. It offers both manual and automatic transmission options.",
    automatic: "Automatic",
    speed: "Petrol",
    gps: "GPS Navigation",
    seatType: "5 seats",
    brand: "Changan Automobile",
    colors: ["black", "white"],
  },
  {
    carName: "MG GT",
    imgUrl1:img13,

    imgUrl: {
      white: img13,
    },
    model: "2024",
    description: "A sporty and stylish sedan that combines good performance with modern features. Equipped with advanced technology including GPS navigation.",
    automatic: "Automatic",
    speed: "Petrol",
    gps: "GPS Navigation",
    seatType: "5 seats",
    brand: "MG Motor",
    colors: ["white"],
  },
];

export default carData;
